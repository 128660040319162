import BaseService from './base.service';

class CustomerService extends BaseService {
  path = () => 'customer';

  getNameByMobileInStore(mobile, storeCode) {
    return this.getAxiosClient().post(`${this.path()}/getNameByMobileInStore`, {
      mobile,
      code: storeCode
    });
  }
}

export default new CustomerService();
