import BaseService from './base.service';

class StaffService extends BaseService {
  path = () => 'staff';

  create(values) {
    const _p = this.path();
    const axiosClient = this.getAxiosClient();
    axiosClient.defaults.headers['Content-Type'] = 'multipart/form-data';
    return axiosClient
      .post(`${_p}/create`, values)
      .then((response) => {
        axiosClient.defaults.headers['Content-Type'] = 'application/json';
        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw this.getDataError(error);
      });
  }
}

export default new StaffService();
