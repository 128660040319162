import { createSlice } from '@reduxjs/toolkit';
import { CustomerService } from '../api/services';

const user = CustomerService.getUser();
// console.log(user);

const initial = {
  name: user?.name ?? '',
  mobile: user?.mobile ?? ''
};

const store = createSlice({
  name: 'auth',
  initialState: initial,
  reducers: {
    addUserInfo: (state, action) => {
      state.name = action.payload.name;
      state.mobile = action.payload.mobile;
      CustomerService.setUser(action.payload);
    }
  }
});

const { reducer, actions } = store;
export const { addUserInfo } = actions;
export default reducer;
