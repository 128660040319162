import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Home = Loader(lazy(() => import('src/content/Home')));
const Redirect = Loader(lazy(() => import('src/content/Redirect')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/redirect" replace />
      },
      {
        path: '/:code',
        element: <Home />
      },
      {
        path: '/redirect',
        element: <Redirect />
      },
      {
        path: '*',
        element: <Navigate to="/redirect" replace />
      }
    ]
  }
];

export default routes;
