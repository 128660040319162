/**
 * Environments variables declared here.
 */

const Environments = {
  API_URL: process.env.REACT_APP_API_URL ?? '',
  HOST_URL: process.env.REACT_APP_API_URL.replaceAll('/api', '') ?? '',
  IMAGE_DEFAULT: '/uploads/%s/default.png'
};

export default Environments;
console.log('Start server with environments', Environments);
