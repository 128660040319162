import { createSlice } from '@reduxjs/toolkit';
import { Booking } from './types/types';

const initial = {
  id: 0,
  name: '',
  mobile: '',
  workDayId: 0,
  products: []
} as Booking;

const store = createSlice({
  name: 'booking',
  initialState: initial,
  reducers: {
    addWorkDay: (state, action) => {
      state.workDayId = action.payload;
    }
  }
});

const { reducer, actions } = store;
export const { addWorkDay } = actions;
export default reducer;
