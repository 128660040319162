import axiosClient from '../axiosClient';
import Environments from '../../common/EnvVars';

export default class BaseService {
  path = () => '';

  getLink = (url: string) => `${this.path()}${url}`;

  getAxiosClient = () => axiosClient;

  getList(limit = 0, offset = 0, data = {}) {
    let url = this.getLink('/list');
    if (limit !== 0 || offset !== 0) {
      url += `?limit=${limit}&offset=${offset}`;
    }
    return this.getAxiosClient().post(url, data);
  }

  getImageFull = (url) => {
    let newUrl = url
      ? url
      : Environments.IMAGE_DEFAULT.replaceAll('%s', this.path);
    return `${Environments.HOST_URL}/${newUrl}`;
  };

  create(data) {
    return this.getAxiosClient().post(`${this.path()}/create`, data);
  }

  delete(id) {
    return this.getAxiosClient().delete(`${this.path()}/delete/${id}`);
  }

  edit(data) {
    return this.getAxiosClient().post(this.getLink('/edit'), data);
  }

  getDataError = (error) => {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return error;
  };

  getLocalRefreshToken() {
    const user = this.getUser();
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = this.getUser();
    return user?.accessToken;
  }

  updateLocalAccessToken(token) {
    let user = this.getUser();
    user.accessToken = token;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem('user');
  }
}
