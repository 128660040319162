import BaseService from './base.service';

class WorkDayService extends BaseService {
  path = () => 'workDay';

  findByDate(date, isAll = false) {
    return this.getAxiosClient().post(`${this.path()}/findByDate`, {
      date,
      isAll
    });
  }

  createMultiple(values) {
    return this.getAxiosClient().post(`${this.path()}/createMultiple`, values);
  }

  convertToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map((x) => parseInt(x, 10));
    return hours * 60 + minutes;
  }

  getName(item): string {
    if (!item) return 'Chưa có';
    let label = `${item.open}-${item.close}`;
    return label;
  }
}

export default new WorkDayService();
