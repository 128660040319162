import BaseService from './base.service';

class BookingService extends BaseService {
  path = () => 'booking';

  changeStatus(id, status) {
    return this.getAxiosClient().post(this.getLink('/changeStatus'), {
      id,
      status
    });
  }

  createBooking(data) {
    return this.getAxiosClient().post(this.getLink('/createBooking'), data);
  }

  getBookingToDate(date = null) {
    return this.getAxiosClient().post(this.getLink('/bookingByDay'));
  }
}

export default new BookingService();
