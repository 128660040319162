import BaseService from './base.service';

class StoreService extends BaseService {
  path = () => 'store';

  create(values) {
    const _p = this.path();
    const axiosClient = this.getAxiosClient();
    axiosClient.defaults.headers['Content-Type'] = 'multipart/form-data';
    return axiosClient
      .post(`${_p}/create`, values)
      .then((response) => {
        // @ts-ignore
        this.setStoreId(response.id);
        axiosClient.defaults.headers['Content-Type'] = 'application/json';
        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw this.getDataError(error);
      });
  }

  myStore() {
    return this.getAxiosClient().post(`${this.path()}/myStore`);
  }

  get(id: string) {
    return this.getAxiosClient().post(`${this.path()}/get/${id}`);
  }

  getWorkDay(id: string, booking_date: string) {
    return this.getAxiosClient().post(`${this.path()}/getWorkDay/${id}`, {
      booking_date
    });
  }

  infoBooking() {
    return this.getAxiosClient().post(this.getLink('/infoBooking'));
  }

  setStoreId(id: number) {
    localStorage.setItem('storeId', `${id}`);
  }

  getStoreId() {
    const storeId = localStorage.getItem('storeId');
    if (storeId) return parseInt(storeId);
    return 0;
  }

  removeStoreId() {
    localStorage.removeItem('storeId');
  }
}

export default new StoreService();
